<template>
    <div class="CreateAndEdit">
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    仪器检查
                </div>
            </div>
            <div class="formbox">
                <el-form :model="keepLogInfo" :rules="rules" ref="keepLogInfo">
                    <el-divider></el-divider>
                    <div class="yq-check">
                        <el-form-item label="仪器及附件外观是否良好" :label-width="formLabelWidth" prop="isGuiseGood">
                            <el-radio v-model="keepLogInfo.isGuiseGood" :label=0>是</el-radio>
                            <el-radio v-model="keepLogInfo.isGuiseGood" :label=1>否</el-radio>
                        </el-form-item>
                        <el-form-item label="说明原因" :label-width="formLabelCauseWidth" prop="guiseDesc" class="yq-cause"
                            v-if="keepLogInfo.isGuiseGood === 1">
                            <el-input v-model="keepLogInfo.guiseDesc" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="yq-check">
                        <el-form-item label="配置是否完整" :label-width="formLabelWidth" prop="isPzGood">
                            <el-radio v-model="keepLogInfo.isPzGood" :label=0>是</el-radio>
                            <el-radio v-model="keepLogInfo.isPzGood" :label=1>否</el-radio>
                        </el-form-item>
                        <el-form-item label="说明原因" :label-width="formLabelCauseWidth" prop="pzDesc" class="yq-cause"
                            v-if="keepLogInfo.isPzGood === 1">
                            <el-input v-model="keepLogInfo.pzDesc" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>

                    <div class="yq-check">
                        <el-form-item label="通电自检是否正常" :label-width="formLabelWidth" prop="isTdzjNormal">
                            <el-radio v-model="keepLogInfo.isTdzjNormal" :label=0>是</el-radio>
                            <el-radio v-model="keepLogInfo.isTdzjNormal" :label=1>否</el-radio>
                        </el-form-item>
                        <el-form-item label="说明原因" :label-width="formLabelCauseWidth" prop="tdzjDesc" class="yq-cause"
                            v-if="keepLogInfo.isTdzjNormal === 1">
                            <el-input v-model="keepLogInfo.tdzjDesc" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>

                    <div class="yq-check">
                        <el-form-item label="是否需要充电" :label-width="formLabelWidth" prop="isNeedCharge">
                            <el-radio v-model="keepLogInfo.isNeedCharge" :label=0>是</el-radio>
                            <el-radio v-model="keepLogInfo.isNeedCharge" :label=1>否</el-radio>
                        </el-form-item>
                        <el-form-item label="说明原因" :label-width="formLabelCauseWidth" prop="chargeDesc" class="yq-cause"
                            v-if="keepLogInfo.isNeedCharge === 1">
                            <el-input v-model="keepLogInfo.chargeDesc" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="仪器仪表状态" :label-width="formLabelWidth" prop="deviceNameCode">
                        <el-select placeholder="请选择仪器状态" v-model="keepLogInfo.deviceStatus">
                            <el-option v-for="item in deviceStatusList" :key="item.code" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="仪器检查图片" :label-width="formLabelWidth" required >
                        <uploadImg :imageUrl="keepLogInfo.yqjcImg" @imgVal="updateImg($event, 'yqjcImg')" />
                    </el-form-item>
                    <h1>开机通电</h1>
                    <el-divider></el-divider>
                    <el-form-item label="上次使用时间" :label-width="formLabelWidth" prop="scsyDate">
                        <el-date-picker v-model="keepLogInfo.scsyDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" disabled>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="通电时长" :label-width="formLabelWidth" prop="powerTime">
                        <el-select placeholder="请选择通电时长" v-model="keepLogInfo.powerTime">
                            <el-option v-for="item in powerTimeList" :key="item.code" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开机通电图片" :label-width="formLabelWidth" required>
                        <uploadImg :imageUrl="keepLogInfo.kjtdImg" @imgVal="updateImg($event, 'kjtdImg')" />
                    </el-form-item>

                    <h1>除尘清洁</h1>
                    <el-divider></el-divider>
                    <div class="yq-check">
                        <el-form-item label="清洁除尘是否完成" :label-width="formLabelWidth" prop="isQjccAchieve">
                            <el-radio v-model="keepLogInfo.isQjccAchieve" :label=0>是</el-radio>
                            <el-radio v-model="keepLogInfo.isQjccAchieve" :label=1>否</el-radio>
                        </el-form-item>
                        <el-form-item label="说明原因" :label-width="formLabelCauseWidth" prop="qjccDesc" class="yq-cause"
                            v-if="keepLogInfo.isQjccAchieve === 1">
                            <el-input v-model="keepLogInfo.qjccDesc" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="除尘清洁图片" :label-width="formLabelWidth" required>
                        <uploadImg :imageUrl="keepLogInfo.ccqjImg" @imgVal="updateImg($event, 'ccqjImg')" />
                    </el-form-item>

                    <h1>电源充电</h1>
                    <el-divider></el-divider>
                    <div class="yq-check">
                        <el-form-item label="是否完成充电" :label-width="formLabelWidth" prop="isChargeAchieve">
                            <el-radio v-model="keepLogInfo.isChargeAchieve" :label=0>不需充电/已完成</el-radio>
                            <el-radio v-model="keepLogInfo.isChargeAchieve" :label=1>未完成</el-radio>
                        </el-form-item>
                        <el-form-item label="说明原因" :label-width="formLabelCauseWidth" prop="chargeAchieveDesc"
                            class="yq-cause" v-if="keepLogInfo.isChargeAchieve === 1">
                            <el-input v-model="keepLogInfo.chargeAchieveDesc" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="电源充电图片" :label-width="formLabelWidth" required>
                        <uploadImg :imageUrl="keepLogInfo.dycdImg" @imgVal="updateImg($event, 'dycdImg')" />
                    </el-form-item>

                    <h1>维护保养信息</h1>
                    <el-divider></el-divider>
                    <el-form-item label="保养日期" :label-width="formLabelWidth" prop="keepDate">
                        <el-date-picker v-model="keepLogInfo.keepDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="保养人员" :label-width="formLabelWidth" prop="keepPersonnel">
                        <el-input v-model="keepLogInfo.keepPersonnel" autocomplete="off"></el-input>
                    </el-form-item>

                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>

    </div>
</template>

<script>
import uploadImg from '@/components/UploadImg/index'
import { deviceStatusList, powerTimeList, keepRules, addDeviceKeepLog } from '@/api/deviceKeepLog.js'
import { queryDevice } from '@/api/device.js'
export default {
    components: {
        uploadImg
    },
    props: {
        did: {
            type: [String, Number]
        }
    },
    data() {
        return {
            powerTimeList: powerTimeList,
            deviceStatusList: deviceStatusList,
            keepLogInfo: {
                id: null,
                did: this.did,
                isGuiseGood: 0,
                guiseDesc: null,
                isPzGood: 0,
                pzDesc: null,
                isTdzjNormal: 0,
                tdzjDesc: null,
                isNeedCharge: 0,
                chargeDesc: null,
                deviceStatus: null,
                yqjcImg: null,
                scsyDate: null,
                powerTime: null,
                kjtdImg: null,
                isQjccAchieve: 0,
                qjccDesc: null,
                ccqjImg: null,
                isChargeAchieve: 0,
                chargeAchieveDesc: null,
                dycdImg: null,
                keepDate: null,
                keepPersonnel: null,
                recordTime: null
            },
            formLabelWidth: '200px',
            formLabelCauseWidth: '80px',
            rules: keepRules,
            submitFlag: false,// 是否提交中,

        }
    },
    mounted() {
        this.loadDevice()
    },
    methods: {

        async loadDevice(){
            await queryDevice(this.did).then(res=>{
                if(res.code==='000000'){
                    this.keepLogInfo.scsyDate = res.t.scsyDate
                }
            })
        },

        //提交
        submitForm() {
            this.submitFlag = true
            this.$refs.keepLogInfo.validate((valid) => {
                if (valid) {
                    addDeviceKeepLog(this.keepLogInfo).then(res => {
                        if (res.code === '000000') {
                            this.$message.success("新增维护保养记录成功！")
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 1000)
                        }
                        this.submitFlag = false
                    })
                }
            })
        },

        handleReset() {
            this.$refs.keepLogInfo.resetFields()
        },

        // 上传的图片
        updateImg(val, item) {
            console.log(val)
            this.keepLogInfo[item] = val
        }

    }
}

</script>


<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }

        .clearfix {
            text-align: left;
        }
    }

    .yq-check {
        display: flex;

        .el-form-item {
            width: 40%;
        }

        .yq-cause {
            text-align: left;
        }
    }

    .el-form-item {
        width: 80%;
    }

    .df {
        text-align: left;
    }

    .input-lenght {
        width: 500px;
    }

    .upload1 {
        width: 360px;
        height: 240px;
        margin-right: 20px;
        margin-bottom: 20px;

        .el-upload-dragger,
        .el-upload--text,
        .upload-demo1 {
            width: 100%;
            height: 100%;
        }

        .el-upload-dragger .el-icon-upload {
            margin-top: 60px;
        }

        .el-upload__text {
            margin-top: 20px;
        }
    }

    .bannerbox {
        display: flex;
        flex-wrap: wrap;
    }

    .banners {
        width: 360px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;

        .cha {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            z-index: 2;
        }

        .el-upload__tip {
            margin-top: 7px;
        }

        .el-upload {
            width: 100%;
        }

        .el-upload-dragger {
            border: none;
            border-radius: 0;
        }
    }

    .avatar {
        width: 100%;
        height: 100%;
    }

    .btns {
        margin-top: 50px;
    }

    .upload-demo {
        width: 360px;
        height: 200px;
    }

    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }


}
</style>